/* appbar */
.MuiAppBar-root.MuiAppBar-colorPrimary {
    background-color: #56b38b !important;
    color: #fff !important;
}

.MainLoading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.PageContent-section {
    min-height: 400px;
    overflow: auto;
    width: 100%;
}

/* drawer */
.MuiDrawer-root .MuiAppBar-root {
    background-color: rgba(84, 86, 156, 1) !important;
    height: 80px;
    color: #fff !important;
}

.MuiDrawer-root .MuiToolbar-root {
    min-height: 80px;
}

.MuiDrawer-root .PropertyTitle-section {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 12px;
    color: #516f90;
    font-weight: 400;
    display: block;
}

.MuiDrawer-root .SelectedDetails-section {
    padding: 50px;
    width: 500px;
}

.MuiDrawer-root .SelectedDetails-section .MuiTypography-root {
    margin-bottom: 20px;
}

/* filter */
.MainSearch-table {
    margin-left: 20px !important;
}

.MainSearch-table label {
    font-size: 13px;
}

.MainSearch-table input {
    padding: 12px 15px;
}

.MainSearch-table .MuiOutlinedInput-notchedOutline {
    border-radius: 15px;
}

.PageTitle-section .MuiToolbar-root {
    padding: 0px;
}

.PageTitle-section .MuiTypography-root {
    color: #565656;
}

/* table */
.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 15px !important;
}

.MuiTable-root tr th {
    border: none;
}

.MuiTable-root tr td {
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.MuiTable-root tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.MuiTable-root tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.MuiTable-root tr td:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

/* buttons */
.MuiButton-outlinedPrimary {
    border: 1px solid rgb(84 86 156) !important;
    color: #54569c !important;
}

.MuiButton-containedPrimary {
    background-color: #54569c !important;
}

/* body */
body {
    max-width: 1400px;
    margin: 100px auto;
    padding-left: 15px;
    padding-right: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.error fieldset {
    border-color: red !important;
    color: red !important;
}

/* footer */
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding: 10px;
    text-align: center;
}

/* home */
.HomeBaner-section {
    padding: 80px 0;
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    background: #f5f5f5a1;
}

.Home-subtitle {
    font-size: 16px;
}

.Home-grid {
    margin-top: 400px !important;
}

.Home-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home-link {
    text-decoration: none;
    width: 100%;
}

.Home-card {
    background-color: #fff;
    color: #666;
    border-radius: 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease;
}

.Home-card-icon {
    background-color: #f5f5f5;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.Home-card-icon svg {
    color: #000;
}


/* deployment wizard */
/* DeploymentWizard Component */
.deployment-wizard {
    padding: 16px;
    width: 1400px;
}

.wizard-container {
    margin-top: 30px;
}

.progress-container {
    text-align: center;
}

.progress-message {
    min-height: 300px;
}

.return-button {
    margin-top: 16px;
}

.step-title {
    margin-bottom: 16px;
}

.left-side-details {
    min-height: 300px;
    padding: 50px;
}

.right-side-details {
    border-left: 1px solid #ccc;
    padding-left: 16px;
}

.detail {
    margin-top: 10px;
}

.hubspot-icon {
    margin-right: 10px;
    font-size: 16px;
    color: #ff5c35;
}

.no-request {
    margin-top: 10px;
}

.text-field {
    margin-top: 1rem;
}

.default-content {
    min-height: 300px;
}

.button-container {
    margin-top: 16px !important;
    ;
    text-align: center !important;
    ;
}

.button-space-after {
    margin-right: 16px !important;
}

.button-space-before {
    margin-left: 16px !important;
}

.tenant-message {
    color: red;
}